export const SiteMapping = [
  {
    name: 'VP-AT-STAGING',
    tenant: 'VP-AT-DEV',
    language: 'de',
    locale: 'de-AT',
  },
  { name: 'VP-AT', tenant: 'VP-AT-PROD', language: 'de', locale: 'de-AT' },
  {
    name: 'VP-AU-STAGING',
    tenant: 'VP-AU-DEV',
    language: 'en',
    locale: 'en-AU',
  },
  { name: 'VP-AU', tenant: 'VP-AU-PROD', language: 'en', locale: 'en-AU' },
  {
    name: 'VP-BE-FR-STAGING',
    tenant: 'VP-BE-DEV',
    language: 'fr',
    locale: 'fr-BE',
  },
  { name: 'VP-BE-FR', tenant: 'VP-BE-PROD', language: 'fr', locale: 'fr-BE' },
  {
    name: 'VP-BE-NL-STAGING',
    tenant: 'VP-BE-DEV',
    language: 'nl',
    locale: 'nl-BE',
  },
  { name: 'VP-BE-NL', tenant: 'VP-BE-PROD', language: 'nl', locale: 'nl-BE' },
  {
    name: 'VP-CA-EN-STAGING',
    tenant: 'VP-CA-DEV',
    language: 'en',
    locale: 'en-CA',
  },
  { name: 'VP-CA-EN', tenant: 'VP-CA-PROD', language: 'en', locale: 'en-CA' },
  {
    name: 'VP-CA-FR-STAGING',
    tenant: 'VP-CA-DEV',
    language: 'fr',
    locale: 'fr-CA',
  },
  { name: 'VP-CA-FR', tenant: 'VP-CA-PROD', language: 'fr', locale: 'fr-CA' },
  {
    name: 'VP-CH-DE-STAGING',
    tenant: 'VP-CH-DEV',
    language: 'de',
    locale: 'de-CH',
  },
  { name: 'VP-CH-DE', tenant: 'VP-CH-PROD', language: 'de', locale: 'de-CH' },
  {
    name: 'VP-CH-FR-STAGING',
    tenant: 'VP-CH-DEV',
    language: 'fr',
    locale: 'fr-CH',
  },
  { name: 'VP-CH-FR', tenant: 'VP-CH-PROD', language: 'fr', locale: 'fr-CH' },
  {
    name: 'VP-CH-IT-STAGING',
    tenant: 'VP-CH-DEV',
    language: 'it',
    locale: 'it-CH',
  },
  { name: 'VP-CH-IT', tenant: 'VP-CH-PROD', language: 'it', locale: 'it-CH' },
  {
    name: 'VP-DE-STAGING',
    tenant: 'VP-DE-DEV',
    language: 'de',
    locale: 'de-DE',
  },
  { name: 'VP-DE', tenant: 'VP-DE-PROD', language: 'de', locale: 'de-DE' },
  {
    name: 'VP-DK-STAGING',
    tenant: 'VP-DK-DEV',
    language: 'da',
    locale: 'da-DK',
  },
  { name: 'VP-DK', tenant: 'VP-DK-PROD', language: 'da', locale: 'da-DK' },
  {
    name: 'VP-ES-STAGING',
    tenant: 'VP-ES-DEV',
    language: 'es',
    locale: 'es-ES',
  },
  { name: 'VP-ES', tenant: 'VP-ES-PROD', language: 'es', locale: 'es-ES' },
  {
    name: 'VP-ES-US-STAGING',
    tenant: 'VP-US-DEV',
    language: 'es',
    locale: 'es-US',
  },
  { name: 'VP-ES-US', tenant: 'VP-US-PROD', language: 'es', locale: 'es-US' },
  {
    name: 'VP-FI-STAGING',
    tenant: 'VP-FI-DEV',
    language: 'fi',
    locale: 'fi-FI',
  },
  { name: 'VP-FI', tenant: 'VP-FI-PROD', language: 'fi', locale: 'fi-FI' },
  {
    name: 'VP-FR-STAGING',
    tenant: 'VP-FR-DEV',
    language: 'fr',
    locale: 'fr-FR',
  },
  { name: 'VP-FR', tenant: 'VP-FR-PROD', language: 'fr', locale: 'fr-FR' },
  {
    name: 'VP-GB-STAGING',
    tenant: 'VP-GB-DEV',
    language: 'en',
    locale: 'en-GB',
  },
  { name: 'VP-GB', tenant: 'VP-GB-PROD', language: 'en', locale: 'en-GB' },
  {
    name: 'VP-IE-STAGING',
    tenant: 'VP-IE-DEV',
    language: 'en',
    locale: 'en-IE',
  },
  { name: 'VP-IE', tenant: 'VP-IE-PROD', language: 'en', locale: 'en-IE' },
  {
    name: 'VP-IN-STAGING',
    tenant: 'VP-IN-DEV',
    language: 'en',
    locale: 'en-IN',
  },
  { name: 'VP-IN', tenant: 'VP-IN-PROD', language: 'en', locale: 'en-IN' },
  {
    name: 'VP-IT-STAGING',
    tenant: 'VP-IT-DEV',
    language: 'it',
    locale: 'it-IT',
  },
  { name: 'VP-IT', tenant: 'VP-IT-PROD', language: 'it', locale: 'it-IT' },
  {
    name: 'VP-NL-STAGING',
    tenant: 'VP-NL-DEV',
    language: 'nl',
    locale: 'nl-NL',
  },
  { name: 'VP-NL', tenant: 'VP-NL-PROD', language: 'nl', locale: 'nl-NL' },
  {
    name: 'VP-NO-STAGING',
    tenant: 'VP-NO-DEV',
    language: 'nb',
    locale: 'nb-NO',
  },
  { name: 'VP-NO', tenant: 'VP-NO-PROD', language: 'nb', locale: 'nb-NO' },
  {
    name: 'VP-NZ-STAGING',
    tenant: 'VP-NZ-DEV',
    language: 'en',
    locale: 'en-NZ',
  },
  { name: 'VP-NZ', tenant: 'VP-NZ-PROD', language: 'en', locale: 'en-NZ' },
  {
    name: 'VP-PT-STAGING',
    tenant: 'VP-PT-DEV',
    language: 'pt',
    locale: 'pt-PT',
  },
  { name: 'VP-PT', tenant: 'VP-PT-PROD', language: 'pt', locale: 'pt-PT' },
  {
    name: 'VP-SE-STAGING',
    tenant: 'VP-SE-DEV',
    language: 'sv',
    locale: 'sv-SE',
  },
  { name: 'VP-SE', tenant: 'VP-SE-PROD', language: 'sv', locale: 'sv-SE' },
  {
    name: 'VP-SG-STAGING',
    tenant: 'VP-SG-DEV',
    language: 'en',
    locale: 'en-SG',
  },
  { name: 'VP-SG', tenant: 'VP-SG-PROD', language: 'en', locale: 'en-SG' },
  {
    name: 'VP-US-STAGING',
    tenant: 'VP-US-DEV',
    language: 'en',
    locale: 'en-US',
  },
  { name: 'VP-US', tenant: 'VP-US-PROD', language: 'en', locale: 'en-US' },
];
