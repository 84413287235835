import { clientMount } from '@vp/ubik-fragment-react';
import type { MountWithRootOptions } from '@vp/ubik-fragment-types';
import { Fragment, type Props } from '../fragment';

import { IdentityProvider } from '@vp/ubik-context';
import { createAuthConfig } from '../util/auth';

export const mount: MountWithRootOptions<Props> = async (
  rootElement,
  renderProps,
  rootOptions
) => {
  const { locale } = renderProps;

  const component = (
    <IdentityProvider auth={createAuthConfig(locale)}>
      <Fragment {...renderProps} />
    </IdentityProvider>
  );

  return clientMount(component, rootElement, rootOptions);
};
