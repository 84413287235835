import { defaultQuickCartItems } from '@vp/common-dev-tooling';
import { FlexBox, Typography, Button } from '@vp/swan';
import useIdentityContext from '../../../../shared/src/hooks/useIdentityContext';
import { SiteMapping } from '../constants/sites';
import { FlexColumn } from './shared/FlexColumn';

export const Sites = () => {
  const { identity } = useIdentityContext();
  const { shopperId, isSignedIn, anonymousUserId } = identity;

  const cartShopperId = isSignedIn ? shopperId : anonymousUserId;

  return (
    <>
      <FlexBox
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <FlexColumn justifyContent="left">
          <Typography fontWeight="bold">Client Site</Typography>
        </FlexColumn>
        <FlexColumn justifyContent="center">
          <Typography fontWeight="bold">Vistacart Tenant</Typography>
        </FlexColumn>
        <FlexColumn justifyContent="center">
          <Typography fontWeight="bold">Language/Locale</Typography>
        </FlexColumn>
        <FlexColumn justifyContent="right">
          <Typography fontWeight="bold">Quick Cart</Typography>
        </FlexColumn>
      </FlexBox>
      {SiteMapping.map((site) => {
        const { name, tenant, language, locale } = site;
        return (
          <FlexBox flexDirection="column" key={name} my={3}>
            <FlexBox
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <FlexColumn>
                <Typography>{name}</Typography>
              </FlexColumn>
              <FlexColumn justifyContent="center">
                <Typography data-testid={`${name}-tenant`}>{tenant}</Typography>
              </FlexColumn>
              <FlexColumn justifyContent="center">
                <Typography>
                  <span>{language}, </span>
                  <span data-testid={`${name}-locale`}>{locale}</span>
                </Typography>
              </FlexColumn>
              <FlexColumn justifyContent="right">
                {defaultQuickCartItems ? (
                  <>
                    {defaultQuickCartItems.map((item, i) => (
                      <Button
                        disabled={false}
                        skin="secondary"
                        marginRight="2"
                        compactMode={true}
                        onClick={async () => {
                          console.log('Clicked', item.productType);
                          try {
                            await item.addToCartFn({
                              locale,
                              tenantName: tenant,
                              requestor: 'quickcart',
                              shopperId: cartShopperId,
                            });
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                        data-testid={`${name}-${item.productType}`}
                        key={i}
                      >
                        {item.icon}
                        →🛒
                      </Button>
                    ))}
                  </>
                ) : null}
              </FlexColumn>
            </FlexBox>
          </FlexBox>
        );
      })}
    </>
  );
};
