import { createContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import VistaprintAuth from '@vp/auth';
import { buildAuthConfigurationByHostEntry } from '@vp/common-dev-tooling';
import useSiteData from '../../../../../shared/src/hooks/useSiteData';

const IdentityContext = createContext();
const DEFAULT_LOCALE = 'en-IE';

const IdentityProvider = (props) => {
  const { children } = props;
  const [auth, setAuthState] = useState();
  const [isIdentityInitialized, setIdentityInitialized] = useState(false);
  const [identity, setIdentity] = useState();
  const { locale: siteLocale, authSite } = useSiteData();

  useEffect(() => {
    const locale = siteLocale || DEFAULT_LOCALE;
    const authConfigOverrides = buildAuthConfigurationByHostEntry(
      locale,
      authSite
    );
    VistaprintAuth.init(authConfigOverrides, () => {
      const authLibrary = new VistaprintAuth.WebAuth();
      setAuthState(authLibrary);
    });
  }, [authSite, siteLocale]);

  /**
   * Only attach this event handler once the auth library becomes available.
   * Since auth library is set in a run-once effect (above) this will only run a maximum once.
   */
  useEffect(() => {
    if (!auth) return;

    setIdentity(auth.getIdentity());

    auth.onUserIdentityUpdate((newIdentity) => {
      if (!newIdentity) {
        return;
      }
      setIdentityInitialized(true);
      setIdentity(newIdentity);
    });
  }, [auth]);

  const identityContextValues = useMemo(
    () => ({ isIdentityInitialized, identity, auth }),
    [isIdentityInitialized, identity, auth]
  );

  return (
    <IdentityContext.Provider value={identityContextValues}>
      {children}
    </IdentityContext.Provider>
  );
};

IdentityProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { IdentityContext, IdentityProvider };
