import { FlexBox, type FlexBoxProps } from '@vp/swan';
import type { ReactNode } from 'react';

interface FlexColumnProps extends FlexBoxProps {
  children: ReactNode;
}

export const FlexColumn = ({ children, ...rest }: FlexColumnProps) => {
  const { justifyContent = 'left', ...other } = rest;
  return (
    <FlexBox
      justifyContent={justifyContent}
      style={{ flexBasis: '20%', flexGrow: 1 }}
      {...other}
    >
      {children}
    </FlexBox>
  );
};
