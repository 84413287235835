import { DevHomeAuthPanel } from '@vp/common-dev-tooling';
import {
  Column,
  FlexBox,
  GridContainer,
  H1,
  Row,
  Spinner,
  SWAN_STYLE_KEY_MAP,
} from '@vp/swan';
import { useSwanStyleKeys } from '@vp/ubik-context';
import useIdentityContext from '../../../shared/src/hooks/useIdentityContext';
import { Sites } from './components/Sites';

export type Props = {
  locale: string;
  tenant?: string;
  environment?: string;
};

function App() {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.icon,
  ]);

  const { isIdentityInitialized, auth } = useIdentityContext();

  if (!isIdentityInitialized) {
    return (
      <FlexBox justifyContent="center">
        <Spinner
          size="super"
          accessibleText="loading..."
          aria-busy="true"
          aria-live="polite"
          role="status"
          my={9}
        />
      </FlexBox>
    );
  }

  return (
    <GridContainer>
      <Row>
        <Column span={12}>
          <FlexBox justifyContent="center" mb="7">
            <H1 mb="5" mt="5" textColor="subtle">
              Development Home
            </H1>
          </FlexBox>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <DevHomeAuthPanel auth={auth} />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Sites />
        </Column>
      </Row>
    </GridContainer>
  );
}

export default App;
